import React from "react";
import { Link } from "react-router-dom";
// texts
import { texts } from "../lang/texts";

const Footer = ({ lang }) => {
  return (
    <footer className="py-32">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center font-secondary font-bold">
          <div className="flex-1 flex justify-center items-center">
            ©2023 JB Development
          </div>
          <div className="flex-1 flex justify-center items-center">
            <Link to={texts[lang].imprint.route}>
              {texts[lang].imprint.title}
            </Link>
          </div>
          <div className="flex-1 flex justify-center items-center">
            <Link to={texts[lang].privacy.route}>
              {texts[lang].privacy.title}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
