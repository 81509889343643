import React from "react";
import { Link } from "react-router-dom";
// motion
import { motion } from "framer-motion";
// icons
import { BiX } from "react-icons/bi";
// functions
import ScrollToTop from "../functions/ScrollToTop";
// texts
import { texts } from "../lang/texts";

const Privacy = ({ lang }) => {
  return (
    <div>
      <ScrollToTop />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="container mx-auto"
      >
        {/* header */}
        <header className="py-8 flex justify-between items-center">
          <div className="text-4xl font-bold">{texts[lang].privacy.title}</div>
          <Link to="/" className="btn btn-sm flex items-center">
            <BiX className="text-xl" />
          </Link>
        </header>
        {/* content */}
        <div className="items-center text-base">
          <div className="mt-16">
            <h1 className="text-3xl font-bold mb-5">
              {texts[lang].privacy.header}
            </h1>
            <h2 className="text-2xl font-bold mb-5">
              {texts[lang].privacy.section1.title}
            </h2>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section1.subtitle1}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section1.text1}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section1.subtitle2}
            </h3>{" "}
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section1.subtitle3}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section1.text2}</p>{" "}
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section1.subtitle4}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section1.text3}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section1.text4}</p>{" "}
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section1.subtitle5}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section1.text5}</p>{" "}
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section1.subtitle6}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section1.text6}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section1.text7}</p>
            <h2 className="text-2xl font-bold mb-5">
              {texts[lang].privacy.section2.title}
            </h2>
            <p className="mb-5">{texts[lang].privacy.section2.text1}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section2.subtitle1}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section2.text2}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section2.text3}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section2.text4}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section2.text5}</p>
            <p className="mb-5">
              <a
                href="https://www.netcup.de"
                target="_blank"
                className="underline"
              >
                netcup GmbH
              </a>
              <br />
              Daimlerstra&szlig;e 25
              <br />
              D-76185 Karlsruhe
            </p>
            <h2 className="text-2xl font-bold mb-5">
              {texts[lang].privacy.section3.title}
            </h2>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle1}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text1}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text2}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text3}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle2}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text4}</p>{" "}
            <p className="mb-5">
              {texts[lang].imprint.contact.name}
              <br />
              {texts[lang].imprint.contact.company}
              <br />
              {texts[lang].imprint.contact.street}
              <br />
              {texts[lang].imprint.contact.city}
            </p>
            <p className="mb-5">
              {texts[lang].imprint.contact.phoneTitle}
              <span> </span>
              {texts[lang].imprint.contact.phoneNumber}
              <br />
              {texts[lang].imprint.contact.emailTitle}
              <span> </span>
              {texts[lang].imprint.contact.email}
            </p>
            <p className="mb-5">{texts[lang].privacy.section3.text5}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle3}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text6}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle4}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text7}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle5}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text8}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle6}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text9}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle7}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text10}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text11}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle8}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text12}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle9}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text13}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle10}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text14}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle11}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text15}</p>{" "}
            <ul>
              {" "}
              <li className="list-disc list-inside">
                {texts[lang].privacy.section3.text16}
              </li>{" "}
              <li className="list-disc list-inside">
                {texts[lang].privacy.section3.text17}
              </li>{" "}
              <li className="list-disc list-inside">
                {texts[lang].privacy.section3.text18}
              </li>{" "}
              <li className="list-disc list-inside">
                {texts[lang].privacy.section3.text19}
              </li>{" "}
            </ul>{" "}
            <p className="mb-5">
              <br />
              {texts[lang].privacy.section3.text20}
            </p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section3.subtitle12}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text21}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section3.text22}</p>
            <h2 className="text-2xl font-bold mb-5">
              {texts[lang].privacy.section4.title}
            </h2>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section4.subtitle1}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section4.text1}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section4.text2}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section4.text3}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section4.subtitle2}
            </h3>{" "}
            <p className="mb-5">{texts[lang].privacy.section4.text4}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section4.text5}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section4.text6}</p>
            <h2 className="text-2xl font-bold mb-5">
              {texts[lang].privacy.section5.title}
            </h2>
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section5.subtitle1}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text1}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text2}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text3}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text4}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text5}</p>{" "}
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section5.subtitle2}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text6}</p>{" "}
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section5.subtitle3}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text7}</p>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text8}</p>{" "}
            <h4 className="text-lg font-bold mb-5">
              {texts[lang].privacy.section5.subtitle4}
            </h4>{" "}
            <p className="mb-5">{texts[lang].privacy.section5.text9}</p>
            <h3 className="text-xl font-bold mb-5">
              {texts[lang].privacy.section5.subtitle5}
            </h3>{" "}
            <p className="mb-5">
              {texts[lang].privacy.section5.text10}
              <a
                href={texts[lang].privacy.section5.url}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {texts[lang].privacy.section5.text11}
              </a>
              .
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
export default Privacy;
