import React from "react";
import { Link } from "react-router-dom";
// motion
import { motion } from "framer-motion";
// icons
import { BiX } from "react-icons/bi";
// functions
import ScrollToTop from "../functions/ScrollToTop";
// texts
import { texts } from "../lang/texts";

const Imprint = ({ lang }) => {
  return (
    <div>
      <ScrollToTop />
      <div className="container mx-auto">
        {/* header */}
        <header className="py-8 flex justify-between items-center">
          <div className="text-4xl font-bold">{texts[lang].imprint.title}</div>
          <Link to="/" className="btn btn-sm flex items-center">
            <BiX className="text-xl" />
          </Link>
        </header>
        {/* content */}
        <div className="items-center text-base">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mt-16 text-xl lg:text-2xl"
          >
            {texts[lang].imprint.description}
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="text-3xl mb-16 mt-16"
          >
            <strong>{texts[lang].imprint.header}</strong>
          </motion.div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* box */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col justify-start items-start w-full border rounded-2xl p-6"
            >
              <div className="font-bold mb-4 text-xl border-b w-full">
                {texts[lang].imprint.contact.title}
              </div>
              <div className="mb-4">
                <p>
                  {texts[lang].imprint.contact.name}
                  <br />
                  {texts[lang].imprint.contact.company}
                  <br />
                  {texts[lang].imprint.contact.street}
                  <br />
                  {texts[lang].imprint.contact.city}
                </p>
              </div>
              <div>
                <p>
                  <span className="font-bold">
                    {texts[lang].imprint.contact.phoneTitle}
                  </span>{" "}
                  <span>{texts[lang].imprint.contact.phoneNumber}</span>
                  <br />
                  <span className="font-bold">
                    {texts[lang].imprint.contact.emailTitle}
                  </span>{" "}
                  <span>{texts[lang].imprint.contact.email}</span>
                </p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col justify-start items-start w-full border rounded-2xl p-6"
            >
              <div className="font-bold mb-4 text-xl border-b w-full">
                {texts[lang].imprint.vat.title}
              </div>
              <div className="mb-4">
                <p>
                  {texts[lang].imprint.vat.text}
                  <br />
                  <strong>{texts[lang].imprint.vat.vatNo}</strong>
                </p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col justify-start items-start w-full border rounded-2xl p-6"
            >
              <div className="font-bold mb-4 text-xl border-b w-full">
                {texts[lang].imprint.eu.title}
              </div>
              <div className="mb-4">
                <p>
                  {texts[lang].imprint.eu.text}
                  <a
                    href="https://ec.europa.eu/consumers/odr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    {texts[lang].imprint.eu.url}
                  </a>
                  .<br /> {texts[lang].imprint.eu.text2}
                </p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col justify-start items-start w-full border rounded-2xl p-6"
            >
              <div className="font-bold mb-4 text-xl border-b w-full">
                {texts[lang].imprint.arbitration.title}
              </div>
              <div className="mb-4">
                <p>{texts[lang].imprint.arbitration.text}</p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Imprint;
