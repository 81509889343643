import React from "react";
// link
import { Link } from "react-scroll";
// motion
import { motion } from "framer-motion";
// variant
import { fadeIn } from "../variants";
// images
import Image from "../assets/about.png";
// texts
import { texts } from "../lang/texts";

const About = ({ lang }) => {
  return (
    <section className="py-16 section" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-10 lg:flex-row items-center lg:gap-x-44">
          {/* img */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 max-w-[200px] lg:max-w-[400px] mix-blend-lighten"
          >
            <img src={Image} alt="" />
          </motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <h2 className="h2 text-gradient">{texts[lang].about.title}</h2>
            <h3 className="h3 mb-10">{texts[lang].about.subtitle}</h3>
            <p className="mb-8 font-tertiary leading-tight">
              {texts[lang].about.description}
            </p>
            <div className="flex gap-x-8 items-center">
              <Link
                to="contact"
                activeClass="active"
                smooth={true}
                spy={true}
                className="btn btn-lg flex items-center justify-center"
              >
                {texts[lang].banner.button}
              </Link>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
