import React from "react";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// texts
import { texts } from "../lang/texts";

const Contact = ({ lang }) => {
  return (
    <section className="py-16 section" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-col lg:gap-x-44">
          {/* text */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div>
              <h4 className="text-xl uppercase font-medium mb-2 tracking-wide text-gradient">
                {texts[lang].contact.title}
              </h4>
              <h3 className="h3 leading-none mb-12">
                {texts[lang].contact.subtitle}
              </h3>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
          >
            <p className="font-tertiary leading-tight mb-10">
            {texts[lang].contact.description}
            </p>
            <p className="font-tertiary leading-tight mb-10">
              info@jb-development.de
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
