import React from "react";
// link
import { Link } from "react-scroll";
// images
import LogoBlack from "../assets/logo-black.png";
import LogoWhite from "../assets/logo-white.png";
// icons
import { FaXing, FaLinkedinIn } from "react-icons/fa";
// type animation
import { TypeAnimation } from "react-type-animation";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// texts
import { texts } from "../lang/texts";

const Banner = ({ lang }) => {
  function isMobile() {
    if (window.innerWidth < 960) {
      console.log();
      return true;
    }
    console.log();
    return false;
  }

  return (
    <section
      className="min-h-[75vh] lg:min-h-[78vh] flex items-center"
      id="home"
    >
      <div className="container mx-auto">
        <div className="flex gab-y-8 flex-col-reverse lg:flex-row items-center">
          {/* text */}
          <div className="flex-1 text-center font-secondary lg:text-left">
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[55px] font-bold leading-[0.8] lg:text-[80px]"
            >
              JEREMY <span>BRANDSCHEIDT</span>
            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-[36px] lg:text-[40px] font-semibold uppercase leading-[1]"
            >
              <span className="text-white mr-4">I am a</span>
              <TypeAnimation
                sequence={["Developer", 2000, "Freelancer", 2000]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn("up", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-8 max-w-lg mx-auto lg:mx-0 font-tertiary leading-tight"
            >
              {texts[lang].banner.text}
            </motion.p>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0"
            >
              <Link
                to="contact"
                activeClass="active"
                smooth={true}
                spy={true}
                className="btn btn-lg flex items-center justify-center"
              >
                {texts[lang].banner.button}
              </Link>
            </motion.div>
            {/* socials */}
            <motion.div
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0"
            >
              <a
                href="https://www.xing.com/profile/Jeremy_Brandscheidt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXing />
              </a>
              <a
                href="https://de.linkedin.com/in/jeremy-brandscheidt-727798225?trk=org-employees"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </motion.div>
          </div>
          {/* image */}
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="lg:flex flex-1 max-w-[200px] lg:max-w-[475px] mb-12 lg:mb-0"
          >
            <img src={isMobile() ? LogoWhite : LogoBlack} alt=""/>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
