// components
import Banner from "../components/Banner";
import Nav from "../components/Nav";
import About from "../components/About";
import Services from "../components/Services";
import Contact from "../components/Contact";

const Main = ({ lang }) => {
  return (
    <div>
      <Banner lang={lang} />
      <Nav lang={lang} />
      <About lang={lang} />
      <Services lang={lang} />
      <Contact lang={lang} />
    </div>
  );
};

export default Main;
